<template>
  <div>
    {{ message }}
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      message: "About页",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped></style>
